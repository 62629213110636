.record-image {
    flex-grow:inherit;
  }
  
  .logo-container {
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center children horizontally in the container */
    align-items: center;
    flex: 1;
    display: flex;
    padding-top: 1%;
    border-bottom: 0px solid black;
    background-color: white;
    height: 10%; /* Or your desired size */
    margin-bottom: 0%; /* Space between the logo and the first link */
    text-align: center;
    padding-bottom: 1%;
  }
  
  .navbar-logo {
    height: auto;
    width: 450px; /* Space between the logo and the first link */
  }

  .logo-container .sju {
    max-width: 30vw; /* Maximum width is 50% of the viewport width */
    max-height: 30vh; /* Maximum height is 50% of the viewport height */
    width: auto; /* Width auto ensures the image maintains its aspect ratio */
    height: auto; /* Height auto ensures the image maintains its aspect ratio */
    margin-left: 0%;
  }

  .date-display {
    margin-top: 0.5rem; /* Space between the logo and the date */
    font-size: 1.2rem; /* Adjust the font size as needed */
    color: #B22222; /* Set the color for the date text */
    margin-bottom: 15px;
  }

    /* Responsive styles for small screens */
@media (min-width: 600px) and (max-width: 1200px) {
  .logo-container .navbar-logo {
    /* Set a smaller width for the logo */
    width: 40%; /* Make the image responsive */
  }
}

  /* Responsive styles for small screens */
@media (max-width: 600px) {
  .logo-container .navbar-logo {
    /* Set a smaller width for the logo */
    width: 80%; /* Make the image responsive */
     
  }

  .logo-container .date-display {
    /* Set a smaller font-size for the date text */
    font-size: 10px; /* Adjust as needed */
    margin-bottom: 10px;
  }
}