.author-articles {
    width: 90%;  /* Adjust this to your desired width, e.g., 80%, 70%, etc. */
    max-width: 1200px;  /* Optional: set a maximum width */
    margin-top: 10px;  /* Top-bottom margin and auto left-right margin for centering */
    margin-bottom: 80px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 2px;
    background-color: #ffffff;
    text-align: center;
}

.articles-row-authorPage {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;  /* Adjust spacing between articles if needed */
    gap: 10px;
}

.article-image-author {
  display: block;       /* Images are inline by default. Change to block to apply margin */
  margin: 0 auto;       /* This will apply an automatic margin to left and right */
  max-width: 100%;      /* This ensures the image is responsive and doesn't overflow its container */
  max-height: 250px;
  height: auto; 
}

.article-card-authorPage {
  flex: 1;  
  margin: 0 20px 20px 20px;  /* Top: 0, Right: 15px, Bottom: 20px, Left: 15px */
  /* width: calc(33.33% - 30px);  Ensuring that after adding the margin, the width still sums up to be about one-third of the container width */
  overflow: hidden;
  border: 3px solid black;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px 0px #D3D3D3; */
  padding: 10px;
}

hr {
    border: 0; /* Remove default border */
    height: 1px; /* Set the height of the line */
    background: #333; /* Set the color of the line */
    margin-top: 20px; /* Space above the line */
    margin-bottom: 40px; /* Space below the line */
  }

  .title-link-article {
    font-size: 20px; /* Set your desired font size */
    text-decoration: none; /* Remove default underline from the link */
    color: black;
  }

  .title-link-article:hover {
    text-decoration: underline; /* Add underline when hovered */
  }

  .article-card-authorPage {
    flex: 1;  
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
    text-align: center; /* Center text for inline or inline-block elements */
    padding: 10px;
    border: 3px solid black;
    border-radius: 2px;
    /* box-shadow: 0px 0px 10px 0px #D3D3D3; */
  }
  
  .image-container-authorPage {
    width: 100%; /* or a specific width like 300px */
    background-color: white; /* Grey color. Change this if you want a different shade of grey */
    overflow: hidden; /* To ensure no overflow */
    display: flex; /* Use flexbox for alignment */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }

  .no-articles-message {
    text-align: center; 
    padding: 20px; 
    background-color: white; 
    border: 1px solid black; 
    border-radius: 0px; 
    margin: 20px auto; /* Center the div */
    max-width: 600px; /* Set a max-width for larger screens */
  }
  
  .first-message-no-articles, .second-message-no-articles {
    margin-bottom: 15px; 
    line-height: 1.5; /* Improve line spacing */
  }
  
  .authors-list-link {
    text-align: center;
    margin-top: 20px;
  }
  
  .authors-list-link a {
    display: inline-block; /* Make the link a block element */
    background-color: #E8E8E8; /* Black background */
    color: #000; /* White text */
    padding: 10px 20px; /* Padding around the text */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    border: 1px solid black; /* Add a black outline */

  }
  
  