.container-about {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers content horizontally */
  width: 100%;
  justify-content: center; /* This will center the children vertically */

}

.wrapper {
  max-width: 1800px; /* Adjust as needed */
  margin: 0 auto; /* This centers the wrapper */
  width: 100%;
}
.authors-list-link1 {
  margin-top: 20px; /* Adjust as needed */
  text-align: center; /* Center the link */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Optional: adds rounded corners */
  border: 1px solid black; /* Add a black outline */
  background-color: #E8E8E8; /* Black background */

}

.authors-list-link1 a {
  color: #1a1a1a; /* Set link color */
  font-size: 1.2rem; /* Set font size */
  text-decoration: none; /* Optional: removes underline */
}
.whoWeAre {
  width: 60%;
  background-color: white; 
  padding: 20px;
  border-radius: 2px;
  margin-top: 20px;
  text-align: center; /* Centering text */
  border: 2px solid black;
  /* box-shadow: 0px 0px 10px 0px #000000; */
  color: #000000; /* White text */
  line-height: 1.5rem;
}

.whoWeAre h1,
.staff-title-top {
  margin-bottom: 1rem; /* Space below the heading */
}

/* Style for the textarea when editing paragraphs */
textarea {
  width: 100%; /* Full width of its container */
  margin-bottom: 1rem; /* Space below the textarea */
  padding: 0.5rem; /* Padding inside the textarea */
  border: 1px solid #ccc; /* Border for the textarea */
  border-radius: 4px; /* Rounded corners for the textarea */
}

/* Style for buttons */
button {
  padding: 0.5rem 1rem; /* Padding inside the button */
  margin-right: 0.5rem; /* Space to the right of the button */
  border: none; /* No border for the button */
  border-radius: 4px; /* Rounded corners for the button */
  background-color: #007bff; /* Background color for the button */
  color: white; /* Text color for the button */
  cursor: pointer; /* Pointer cursor on hover */
}

button:hover {
  background-color: #0056b3; /* Darker background on hover */
}

.staff {
  width: 100%; /* Adjust this width as needed */
  max-width: 1200px; /* You can set a max-width to prevent it from getting too wide on large screens */
  margin: auto; /* This will apply automatic margins on both sides, centering it horizontally */
}

.staffList {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the list items horizontally in the staffList */
  justify-content: center; /* If you want to center them vertically as well */
}

.staff-ul {
  list-style-type: none; /* Remove default list styling */
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex; /* Use flexbox for layout */
  flex-wrap: wrap; /* Allow wrapping items */
  gap: 60px; /* Space between the items */
  justify-content: center; /* Center items horizontally */
}
.staff-person{
  border: 0px solid black; /* Light border for each item */
}

.draggable-item {
  background-color: #fff; /* White background */
  border: 1px solid black; /* Light border for each item */
  border-radius: 4px; /* Rounded corners */
  margin-bottom: 20px; /* Space between rows */
  width: calc(33.333% - 20px); /* Three items per row minus gap */
  box-sizing: border-box;
  display: flex; /* Display as flex to align children */
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start; /* Align children to the start of the 'x' axis */
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0px 0px 4px 0px #000000;
}

.draggable-item.not-draggable {
  cursor: default; /* Default cursor for non-draggable items */
  background-color: white;
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center horizontally in the flex container */
  align-items: center; /* Center vertically in the flex container */
  text-align: center; /* Center text */
}

/* If there are images inside the .draggable-item, make sure they are styled to fit */
.draggable-item.not-draggable img {
  width: 100%; /* Make images take up full width of the item */
  height: auto; /* Adjust height to maintain aspect ratio */
  object-fit: cover; /* Cover the area without stretching */
}

.staff-title-top {
  text-align: center; /* Center the placeholder text */
}

/* Style for the placeholder when no staff is available */
.staffList p {
  text-align: center; /* Center the placeholder text */
}
/* Adjustments for different screen sizes */
@media (max-width: 600px) {
  .draggable-item {
    flex: 0 1 calc(100% - 20px); /* Full width for small screens, minus the gap */
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .draggable-item {
    flex: 0 1 calc(50% - 20px); /* Half width for medium screens, minus the gap */
  }
}

@media (min-width: 901px) and (max-width: 1200px) {
  .draggable-item {
    flex: 0 1 calc(33.333% - 20px); /* One third width for large screens, minus the gap */
  }
}

@media (min-width: 1201px) {
  .draggable-item {
    flex: 0 1 calc(25% - 20px); /* One fourth width for extra-large screens, minus the gap */
  }
}

/* Ensure the image inside the draggable item takes up the full width */
.draggable-item img {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area without stretching */
}