/* styles.css */

/* Style for the overall container */
.container-subscriptions {
    padding: 20px;
    width: 70%;
    margin: auto;
    line-height: 1.6;
    font-family: 'Times New Roman', Times, serif;
    background-color: white;
  }
  
  /* Style for the top section */
  .subscriptions-top {
    margin-bottom: 20px;
  }
  
/* Style for the list items */
.subscriptions-list ul {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
    margin: 0;
  }
  
  .subscriptions-list li {
    position: relative; /* Relative position for pseudo-elements */
    border-radius: 5px;
    padding: 10px;
    margin-bottom: -10px; /* Spacing between list items */
    padding-left: 30px; /* Add space for custom bullet */
  }
  
  /* Custom bullet point using ::before pseudo-element */
  .subscriptions-list li::before {
    content: ''; /* Necessary for pseudo-elements */
    position: absolute; /* Absolute position inside the li */
    left: 0; /* Align to the left side */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Center align the circle */
    width: 7px; /* Circle size */
    height: 7px; /* Circle size */
    background-color: black; /* Circle color */
    border-radius: 50%; /* Make it round */
    margin-left: 10px; /* Spacing from the left edge */
  }
  
  /* Style for the bottom section */
  .subscriptions-bottom {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  /* Style for the address section */
  .address {
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 0.5;
  }
  
  /* Style for the form section */
  .form {
    margin-top: 20px;
  }
  
  /* Form element styles */
  .contact-form input,
  .contact-form textarea {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px; /* Space between form elements */
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #B22222;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Adjust the margin for paragraph elements if needed */
  p {
    margin-bottom: 5px; /* Reduced margin */
  }
  
  /* Styles for editing textarea */
  textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  
  /* Button styles for submit and edit */
  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 5px; /* Reduced space below the button */
  }

  @media (max-width: 1100px) {
    .address {
      margin-top: 20px;
      margin-bottom: 20px;
      line-height: 1.5;
    }
  }
  
