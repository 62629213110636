/* styles.css */

/* Container styles */
.container-contact {
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
  }
  
  /* Heading styles */
  .contact-top h1 {
    color: #333;
    font-size: 2rem;
    margin-bottom: 10px;
    text-align: center;
  }
  
  /* Form styles */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Spacing between form elements */
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .contact-form textarea {
    height: 150px; /* Larger text area for message input */
    resize: vertical; /* Allows the user to resize the textarea vertically */
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #B22222;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #B22222;
  }
  
  /* Paragraph styles if not editing */
  .contact-top p {
    padding: 15px;
    line-height: 1.5;
    margin-bottom: 10px; /* Spacing between paragraphs */
  }
  
  /* Styles for editing mode */
  .contact-top textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .contact-top button {
    background-color: #B22222;
    margin-bottom: 10px;
  }
  
  /* Disabled button styling */
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
  
  /* Style for the editing and submit buttons */
  .contact-top button {
    width: auto;
    padding: 5px 15px;
    font-size: 0.875rem;
  }
  
  /* Add responsive behavior */
  @media (max-width: 768px) {
    .container {
      width: 95%;
    }
  }
  