.container-article-page {
  background-color: white;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80%; 
  margin-left: auto;
  margin-right: auto;
  border-radius: 0px; /* Rounded corners for aesthetics */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

}

.container-article-page h1 {
  margin-top: 40px;
  color: black;
  width: 90%;
}

.article-image {
  max-width: 90%; /* Makes image responsive */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensures the image is scaled properly */
  margin-bottom: 20px 0; /* Adds some space between the image and the title */
}



.author-and-date {
  width: 100%;
  text-align: center; /* Centers the text within the container */
}

.author-email-link-articlePage {
  font-size: 1.1em;
  color: black; /* Set your desired color */
  text-decoration: none; 
}

.author-email-articlePage {
  font-size: 1.1em;
}

.author-email-link-articlePage:hover {
  text-decoration: underline; /* Add underline when hovered */
}

.article-page-contents {
  margin: auto; /* Center the contents */
  width: 90%;
  font-size: 16px;
  line-height: 1.6em;
  text-align: left; /* Aligns text to the left */

}

.article-page-image {
  display: flex;
  justify-content: center; /* Center children horizontally */
  margin-bottom: 20px; 
}

.articlePage-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Adjust the space between images as needed */
  justify-content: center; /* Center the images in the container */
}

.articlePage-images-container.multiple-images img  {
  max-width: 35%; /* Set a max-width for smaller images */
  width: auto; /* Maintain the original image width */
  height: auto; /* Keep the image aspect ratio */
  object-fit: contain; /* Ensures the image is scaled properly */
  max-height: 50%;
}

.articlePage-images-container img {
  width: 80%; /* Full width for single image */
  height: auto;
}

.image-caption {
  text-align: center;
  font-size: 12px;
  margin-top: 10px; /* Space between images and caption */
  width: 80%;
}

/* Media query for small screens */
@media (min-width: 600px) and (max-width: 1200px) {  
  

  .container-article-page h1 {
    margin-top: 40px;
    color: black;
    font-size: 25px;
  }

  .article-page-contents {
    margin: auto; /* Center the contents */
    width: 90%;
    font-size: 15px;
    line-height: 1.6em;
    text-align: left; /* Aligns text to the left */
  
  }

  .author-email-articlePage {
    font-size: 1.5em;
  }

  .author-email-link-articlePage {
    font-size: 1.5em;
    color: black; /* Set your desired color */
    text-decoration: none; 
  }

  .image-caption {
    text-align: center;
    font-size: 10px;
    margin-top: 10px; /* Space between images and caption */
    width: 80%;
  }
}

@media (max-width: 600px) {
  

  .container-article-page h1 {
    margin-top: 40px;
    font-size: 17px;
    color: black;
  }

  .article-page-contents {
    margin: auto; /* Center the contents */
    width: 90%;
    font-size: 15px;
    line-height: 1.6em;
    text-align: left; /* Aligns text to the left */
  
  }

  .author-email-articlePage {
    font-size: 0.9em;
  }

  .author-email-link-articlePage {
    font-size: 0.9em;
    color: black; /* Set your desired color */
    text-decoration: none; 
  }

  .image-caption {
    text-align: center;
    font-size: 9px;
    margin-top: 10px; /* Space between images and caption */
    width: 80%;
  }

  .articlePage-images-container.multiple-images img  {
    max-width: 85%; /* Set a max-width for smaller images */
    width: auto; /* Maintain the original image width */
    height: auto; /* Keep the image aspect ratio */
    object-fit: contain; /* Ensures the image is scaled properly */
    max-height: 50%;
  }
  
  .articlePage-images-container img {
    width: 80%; /* Full width for single image */
    height: auto;
  }
}