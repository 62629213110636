/* Base styles for all screen sizes */
.navbar-bottom {
  display: flex;
  justify-content: center; /* This will center the menu icon */
  align-items: center; /* This will center the menu icon vertically */
  width: 100%;
  background-color: #B22222;
  border-bottom: 0px solid black;
  
}

/* Base styles */
.navbar-bottom .links {
  /* Hide the links by default */
  display: none;
  
}

.navbar-bottom .menu-icon {
  /* Show the menu icon by default and hide it for larger screens */
  display: block; /* Show the menu icon */
  color: white;
  cursor: pointer;
  background-color: #B22222;
  align-items: center;
  left: 50%;
  padding: 5px;
}

/* Styles when links are active */
.navbar-bottom .links.active {
  /* This will only be applied when 'active' class is added to the links */
  display: flex;
  flex-direction: column;
  
}

.navbar-bottom .links a {
  color: white; /* Set text color to black */
  padding: 7px 20px; /* Add padding to increase spacing and for touch targets */
  text-decoration: none; /* Remove underline from links */
  display: block; /* Make each link block level to fill the width */
  width: 100%; /* Each link will fill the full width of the container */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  border-bottom: 1px solid #ddd; /* Add a subtle separator between the links */
}

/* Adjustments for the search input */
.search-input {
  margin-left: 20px; /* Adjust as necessary */
}

/* Hide the menu icon on larger screens and show the links */
@media (min-width: 601px) {
  .navbar-bottom .menu-icon {
    display: none; /* Hide the icon on larger screens */
  }

  .navbar-bottom .links {
    /* Show the links as a horizontal row */
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the links horizontally */
    align-items: center; /* Center the links vertically */
    width: auto; /* Reset width to default */
    position: relative; /* Reset position */
    top: auto; /* Reset top positioning */
    left: auto; /* Reset left positioning */
    background-color: transparent; /* Reset background color */
    z-index: 1; /* Reset z-index */
    padding: 0; /* Reset padding */
    
  }

  .navbar-bottom .links a {
    /* Styles for each link */
    color: white; /* Text color */
    padding: 5px 20px; /* Reset padding */
    text-decoration: none; /* No underline */
    display: inline-flex; /* Inline flex for horizontal layout */
    align-items: center; /* Vertical centering within each link */
    height: 100%; /* Full height */
    box-sizing: border-box; /* Padding included in width/height */
    border-bottom: none; /* Remove separators */
    white-space: nowrap;
  }

  .navbar-bottom .links a:hover {
    color: grey; /* Hover effect */
  }
}
@media (min-width: 800px) {
  .navbar-bottom {
    background-color: #B22222;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; /* changed from center to flex-start */
    padding: 10px; /* reduced top and bottom padding */
    border-bottom: 0px solid black;
    border-top: 0px solid black;
    max-height: 40px; /* or any specific value you prefer */
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    z-index: 1000;
    max-width: 100%;
    box-sizing: border-box;
  }
  
  .navbar-bottom .links {
    display: flex;
    align-items: center;
    height: 100%; /* add a specific height if necessary */
    margin: 0; /* ensures no default margin is applied */
    padding: 0; /* ensures no default padding is applied */
  }
  
  .navbar-bottom .links a {
    color: white;
    text-decoration: none;
    padding: 5px 20px; /* add padding to links for spacing and vertical alignment */
    font-size: 1.5rem;
    display: flex; /* to center text inside the padding */
    align-items: center; /* vertically center the text inside the anchor */
    height: 100%; /* match the parent height */
    box-sizing: border-box; /* ensures padding is included in the height */
    white-space: nowrap;
  }
  
  /* Correct the hover effect to not affect layout */
  .navbar-bottom .links a:hover {
    color: grey;
  }
  
  /* Adjustments for the search input */
  .search-input {
    margin-left: 20px; /* Adjust as necessary */
  }
  
  /* You may need to adjust the line-height of the links if the font is causing alignment issues */
  .navbar-bottom .links a {
    line-height: 1; /* Adjust line height as necessary, may solve the alignment issue */
  }
}
