.container-home {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the content horizontally */
  justify-content: center; /* This centers the content vertically if you also want that */
  min-height: 100vh; /* Full viewport height */
  overflow-x: hidden;
  width: auto;
}

.featured-title-top h1 {
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 8px;
  margin-bottom: 8px;
}

.articles-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* This sets the space between the items */
  justify-content: center;
  /* border: 3px solid #b22222; */
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  background-color: white;
  box-shadow: 0 24px 28px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: 0px solid #b22222;
    /* width: 90%; 
  max-width: 1200px; 
  margin-top: 10px; 
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border: 3px solid #B22222;
  border-radius: 2px;
  background-color: white;
  text-align: center;
  box-shadow: 0px 0px 10px 0px #B22222; */
}

.articles-row {
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; /* Adjust spacing between articles if needed */
}

.article-card {
  flex: 1;
  margin: 0 20px 20px 20px; /* Top: 0, Right: 15px, Bottom: 20px, Left: 15px */
  /* width: calc(33.33% - 30px);  Ensuring that after adding the margin, the width still sums up to be about one-third of the container width */
  overflow: hidden;
  /* border: 3px solid #D3D3D3; */
  border: 3px solid black;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px 0px #D3D3D3; */
}

.article-contents {
  width: 100%; /* Take up the full width */
  box-sizing: border-box; /* Padding and borders are included in the element's total width */
  /* Add any additional styling needed for padding, margin, etc. */
  padding: 0 15px 15px;
  margin: 0; /* Reset any default margins */
  text-align: center; }

.title-link {
  font-weight: bold; /* Make the link text bold */
  font-size: 1em; /* Increase the font size */
  color: black; /* Set the color to black */
  text-decoration: none; /* Remove the underline from the link */
  transition: background-color 0.3s ease;
}

.title-link:hover {
  color: black;
  text-decoration: underline; /* Underline the link on hover */
}

.author-link {
  font-size: 20px; /* Set your desired font size */
  color: black; /* Set your desired color */
  text-decoration: none; /* Remove default underline from the link */
}

.author-link:hover {
  text-decoration: underline; /* Add underline when hovered */
}

.image-container {
  height: 250px; /* Adjust based on your design requirements */
  width: 100%; /* or a specific width like 300px */
  background-color: white; /* Grey color. Change this if you want a different shade of grey */
  overflow: hidden; /* To ensure no overflow */
  display: flex; /* Use flexbox for alignment */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.article-image {
  display: block; /* To remove any spacing at the bottom due to inline nature of images */
  max-height: 100%;
  margin-top: 10px;
}



.articleTitle {
  text-align: center;
}

.section-title {
  text-align: center;
  width: 100%;
  display: block;
}

.more-button {
  display: inline-block;
  padding: 10px 20px; /* Set padding to your liking */
  background-color: grey;
  color: white; /* White text color */
  font-weight: bold; /* Make the button text bold */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove the underline */
  text-align: center; /* Center the text */
  cursor: pointer; /* Hand pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth color transition */
}

.more-button:hover {
  background-color: #b22222; /* Slightly darker red on hover */
}
/* start of featured article css */
.featured-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin-top: 20px;
  height: 100%;
  
}

.featured-article {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  margin-bottom: 0px; /* Adjust as needed */
  width: 100%;
  border: 3px solid black;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px 0px #b22222; */
  height: 100%;
  background-color: white; /* Optional: background color for .otherStuff */

}

.featured-author-link {
  font-size: 0px; /* Set your desired font size */
  text-decoration: none; /* Remove default underline from the link */
  color: #b22222;
}

.featured-author-link:hover {
  text-decoration: underline; /* Add underline when hovered */
}

.featured-email {
    font-size: 20px; /* Set your desired font size */
    color: black; /* Set your desired color */
    text-decoration: none; /* Remove default underline from the link */
  
}

.featured-author-and-email {
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured-image {
  flex-shrink: 0;
  width: 100%; /* Adjust as needed */
  margin-right: 0px;
  object-fit: contain; /* Will maintain aspect ratio */
  max-height: 400px;
}

.featured-text {
  flex-grow: 1;
  min-height: 300px; /* Adjust the height as per your design */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.featured-section {
  flex: 2; /* Takes up 2 parts of the available space */
  /* Your existing styles */
  max-width: 100%; /* Adjust the percentage based on your design */
  margin-left: 0px;
  margin-bottom: 0px;
  padding: 20px;
  
}

.featured-article .featured-text h2 {
  width: 100%;
  font-size: 2.4em; /* Adjust base text size as needed */

}
.featured-article .featured-text .fetaured-description {
  width: 100%;
  font-size: 1.4em; /* Adjust base text size as needed */
  text-align: left;
}

.fetaured-date {
  text-align: center;
}
/* end of featured article css */

/* start of featured article 2 and 3 css */
.featured-and-other {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
    padding: 0px 0; /* Add some vertical padding */
  width: 100%; /* Ensure it takes the full width */
}

.otherFeatured {
  flex: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%; /* Optionally ensure it doesn't grow beyond one-third */
  padding: 20px; /* Add some padding inside the box */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  background-color: white; /* Optional: background color for .otherStuff */
  float: right; /* Align to the right side of the screen */
  display: flex; /* Use flexbox for layout */
  flex-direction: column; /* Stack children vertically */
  max-height: 100%;
  border: 3px solid black;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px 0px #b22222; */
  margin-right: 20px;
  justify-content: space-around; /* Distribute space above, between, and below items */
  align-items: center;
}

.featured-article, .featured2, .featured3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to the top */
  text-align: center;
}

.featured-article2 {
  border-bottom: 1px solid black;
  
}

.featured-article3 {
  padding-top: 10px;  
}

/* Apply a smaller size for featured articles 2 and 3 */
.featured2 .featured-image2,
.featured3 .featured-image3 {
  width: 80%; /* Smaller width, adjust as needed */
  height: auto;
  margin: 0 auto; /* This centers the image in its container */
}

.featured2, .featured3 {
  display: flex;
  flex-direction: column;
  align-items: center; /* This will center the flex children (content blocks) horizontally */
  justify-content: center; /* This will center the flex children vertically if there's space */
  width: 100%;
}

.featured-text2, .featured-text3 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally in flex container */
  justify-content: center; /* Center vertically in flex container */
  text-align: center; /* Center text inside the flex container */
  width: 80%; /* Take up all the width of the parent to center content */
}

.featured-text2 h2, .featured-text2 p,
.featured-text3 h2, .featured-text3 p {
  width: 100%; /* Allow text to take the full width of its parent */
  text-align: left;
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
}

.featured-text2 h2, .featured-text2 h2,
.featured-text3 h2, .featured-text3 h2 {
  width: 100%; /* Allow text to take the full width of its parent */
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 20px;
}



/* Add additional style rules for links if needed */
.title-link2,
.title-link3 {
  text-decoration: none;
  color: black; /* Or any other color */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.author-link2,
.author-link3 {
  text-decoration: none;
  color: black; /* Or any other color */
  align-items: center;
  justify-content: center;
  text-align: center;
}

.author-link2:hover,
.author-link3:hover {
  text-decoration: underline; /* Add underline when hovered */
}

/* Ensure the layout is consistent across different sections */
.featured-content2,
.featured-content3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.featured-author-and-email2,
.featured-author-and-email3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* end of featured article 2 and 3 css */


.article-input form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Adjust width as needed */
  min-width: 800px;
  margin-top: 20px;
}

.article-input form > * {
  width: 100%; /* Set a consistent width for all children */
  margin-bottom: 10px; /* Space between form elements */
  padding: 10px; /* Padding for inputs and textarea */
  box-sizing: border-box; /* Border and padding are included in the width calculation */
}

.article-input form input[type="text"],
.article-input form input[type="email"],
.article-input form input[type="file"],
.article-input form select,
.article-input form textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.article-input form button {
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.set-featured-article {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px; /* This adds some space inside the container */
  box-sizing: border-box; /* This ensures padding doesn't add to the width */
  margin-top: 20px;
  min-width: 800px;
}

.set-featured-article h2 {
  margin-bottom: 20px; /* Space below the header */
}

.set-featured-article input[type="text"] {
  margin-bottom: 10px; /* Spacing between input and button */
  width: 100%; /* Set a width for the input */
  padding: 10px; /* Padding inside the input */
  box-sizing: border-box; /* This ensures padding doesn't add to the width */
}

.set-featured-article button {
  padding: 10px 20px; /* Padding inside the button */
  width: auto; /* Adjust the width of the button as needed */
  cursor: pointer; /* Changes the mouse cursor on hover */
}


/* Responsive styles for screens between 600px and 1150px wide */
@media (min-width: 600px) and (max-width: 1200px) {
  .articles-row {
    flex-direction: column;
    align-items: center;
    /* Removing any left/right margins that might push content off-screen */
    margin-left: 0;
    margin-right: 0;
  }

  .article-card {
    width: 100%; /* Each card takes full width of the container on medium-sized screens */
    margin: 0 auto; /* Centers the card in the available horizontal space */
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px solid black;
    border-radius: 2px;
    /* box-shadow: 0px 0px 10px 0px #D3D3D3; */
    padding: 10px;
  
  }

  .image-container {
    /* Center the image container within the card */
    justify-content: center;
    width: 100%;
    padding-top: 0px;
  }

  .article-image {
    /* Ensure the image fits within the container */
    max-width: 100%;
    height: auto;
    object-fit: cover;
  }

  .featured-section {
    flex: 1; /* Take up full space available */
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .featured-article,
  .featured2,
  .featured3 {
    /* Make sure the featured articles are stacked on top of each other */
    flex-direction: column;
    align-items: center; /* Center align for smaller screens */
    padding: 10px;
  }

  .featured-article .featured-text h2 {
    width: 100%; /* Take full width of the parent container */
    font-size: 28px;
  }
  .featured-article .featured-text p,
  .featured-article .featured-text .author-link,
  .featured-article .featured-text .featured-email {
    font-size: 20px;
  }
  
  .featured2 .featured-text2 h2,
  .featured3 .featured-text3 h2 {
    width: 100%; /* Take full width of the parent container */
    font-size: 20px;
  }
  .featured2 .featured-text2 p,
  .featured3 .featured-text3 p {
    width: 100%; /* Take full width of the parent container */
    font-size: 13px;
  }

  .featured-image,
  .featured2 .featured-image2,
  .featured3 .featured-image3 {
    width: 100%; /* Full width for smaller screens */
    margin-right: 0; /* Adjust or remove margin if necessary */
    object-fit: scale-down; /* Change object-fit if needed */
  }

  .otherFeatured {
    width: 95%; /* Full width for smaller screens */
    margin-top: 10px; /* Adjust margin for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    float: none; /* Disable float for smaller screens */
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }

  .featured-and-other {
    flex-direction: column; /* Stack the featured and other sections vertically */
    justify-content: center;
    padding: 10px 0; /* Adjust padding for smaller screens */
  }
  .featured-article2 {
    border-bottom: 0px solid black;
    
  }
  
  .featured-article3 {
    padding-top: 0px;  
  }
  
}

/* Responsive styles for screens less than 1150px wide */
@media (max-width: 600px) {
  .articles-section {
    padding: 10px;
    width: calc(
      100% - 20px
    ); /* Subtract left and right padding from the width */
    margin: 10px auto; /* Center the section */
    box-sizing: border-box; /* Include padding in the width */
    border: 1px solid #b22222;
    max-height: none; /* Ensures there's no limit to the text's height */
  }

  .articles-row {
    flex-direction: column;
    align-items: center;
  }

  .article-card {
    width: 100%; /* Take up full width */
    /* margin: 20px 0; Only vertical margin for spacing between cards */
    box-sizing: border-box;
    overflow: visible;
    height: 100%; /* Ensures there's no limit to the text's height */
    hyphens: auto;

  }

  .image-container {
    /* Center the image container within the card */
    justify-content: center;
    width: 100%;
    padding-top: 0px;
  }

  .article-image {
    /* Ensure the image fits within the container */
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    
  }

  .article-description {
    /* Ensures that text is not truncated */
    white-space: normal; /* Overrides any non-wrapping text properties */
    overflow-wrap: break-word; /* Ensures words break to prevent overflow */
    hyphens: auto; /* Allows hyphenation of text if needed */
  }

 
  .featured-article,
  .featured2,
  .featured3 {
    /* Make sure the featured articles are stacked on top of each other */
    flex-direction: column;
    align-items: center; /* Center align for smaller screens */
    padding: 0px;
  }

  .featured-article .featured-text h2 {
    width: 100%; /* Take full width of the parent container */
    font-size: 22px;
  }
  .featured-article .featured-text p,
  .featured-article .featured-text .author-link,
  .featured-article .featured-text .featured-email {
    font-size: 14px;
  }
  
  .featured2 .featured-text2 h2,
  .featured3 .featured-text3 h2 {
    width: 100%; /* Take full width of the parent container */
    font-size: 20px;
  }
  .featured2 .featured-text2 p,
  .featured3 .featured-text3 p {
    width: 100%; /* Take full width of the parent container */
    font-size: 12px;
  }

  .featured-image,
  .featured2 .featured-image2,
  .featured3 .featured-image3 {
    width: 100%; /* Full width for smaller screens */
    margin-right: 0; /* Adjust or remove margin if necessary */
    object-fit: scale-down; /* Change object-fit if needed */
  }

  .otherFeatured {
    width: 90%; /* Full width for smaller screens */
    margin-top: 10px; /* Adjust margin for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
    float: none; /* Disable float for smaller screens */
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
  }

  .featured-and-other {
    flex-direction: column; /* Stack the featured and other sections vertically */
    justify-content: center;
    padding: 10px 0; /* Adjust padding for smaller screens */
  }

  .featured-section {
    padding-top: 0px;
  }
  
}
