.App {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Times New Roman', Times, serif;
}

body {
  padding: 0;
  margin: 0;
  background-color: #F5F5F5;
}

