.navbar-top {
  justify-content: space-between; /* Puts leftSide and rightSide at the opposite ends */
  width: 100%;
  height: 35px;
  background-color: white;
  display: flex;
  flex-direction: row;
  border-bottom: 1.5px solid black;
  border-top: 0px solid black;
  font-family: 'Times New Roman', Times, serif;

}

.leftSide {
  flex: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
}

.rightside {
  display: flex;
  align-items: center;
  gap: 25px;
  padding-right: 15px;
}

.rightside a,
.leftSide p,
.leftSide a {
  font-size: 16px; /* Adjust the font size as needed */
  text-decoration: none;
  color: black; /* Example font color, change as needed */
}

.phone,
.email {
  display: flex;
  align-items: center; /* Aligns icon and text vertically */
  gap: 20px; /* Adds a gap between the icon and text */
}

.email-link {
  color: inherit;
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}

.policies-link {
  color: inherit;
  text-decoration: none;
}

.policies-link:hover {
  text-decoration: underline;
}

.advertising-link {
  color: inherit;
  text-decoration: none;
}

.advertising-link:hover {
  text-decoration: underline;
}

.subscription-link {
  color: inherit;
  text-decoration: none;
}

.subscription-link:hover {
  text-decoration: underline;
}

.contact-link {
  color: inherit;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

.auth-link {
  color: inherit;
  text-decoration: none;
}

.auth-link:hover {
  text-decoration: underline;
}

.print-edition {
  color: white; /* Sets the text color to white */
  text-decoration: none; /* Removes the underline from the link */
  font-size: 14px; 
}

.print-edition:hover {
  text-decoration: underline;
}

/* Small Screen Adjustments */
@media only screen and (max-width: 600px) {
  .navbar-top .rightside {
    display: none; /* Hide the rightside content on small screens */
  }
  .navbar-top .leftSide {
    /* Make leftSide take the full width */
    width: 100%;
    /* You may want to center the items if they are not already */
    display: flex;
    align-items: center;
  }
  /* You may also want to adjust the leftSide content to use the full width */
  .navbar-top .leftSide a,
  .navbar-top .leftSide p {
    max-width: none;
    font-size: 10px;
  }
}
