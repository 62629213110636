.authors-page {
    text-align: center; /* Center the heading */
    padding: 20px;
  }
  
  .authors-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
    display: inline-block; /* Center the list */
    text-align: left; /* Align list items to the left */
  }
  
  .authors-list li {
    margin-bottom: 10px; /* Space between list items */
  }
  
  .authors-list li a {
    color: #1a1a1a; /* Link color */
    text-decoration: none; /* Remove underline */
  }
  
  .authors-list li a:hover {
    text-decoration: underline; /* Underline on hover for better UX */
  }
  