.container-search {
  margin-left: 20px;
  position: relative;
  display: flex;
  justify-content: flex-end; /* You can adjust this for positioning */
  align-items: center;
  padding: 1rem;
}

/* Styles for the search icon */
.search-icon {
  font-size: 1.2rem; /* Icon size */
  cursor: pointer;
  color: white; /* Icon color */
  z-index: 10;
}

/* Styles for the search form that pops up */
.search-form {
  position: absolute;
  top: 100%; /* Position right below the navbar */
  right: 0;
  width: 100%; /* Full-width on mobile, adjust as needed */
  min-width: 350px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: for shadow */
  background-color: white; /* Match with navbar or as desired */
  border-radius: 4px;
  display: flex;
  gap: 0.5rem; /* Space between input and button */
  align-items: center;
  justify-content: space-between;
  z-index: 9;
}

/* Styles for the search input field */
.search-input {
  flex-grow: 1; /* Takes up available space */
  border: 1px solid black; 
  padding: 0.5rem;
  border-radius: 4px;
  outline: none; /* Removes default focus outline */
  box-sizing: border-box;
}

/* Styles for the submit button */
.search-form button {
  padding: 0.5rem 1rem;
  border: none;
  background-color: #B22222; /* Bootstrap primary color */
  color: white;
  border-radius: 4px;
  cursor: pointer;
  outline: none; /* Removes default focus outline */
}

/* Override hover state to maintain the same color */
.search-form button:hover {
  background-color: #B22222; /* Same color as the non-hover state */
}

/* Styles for the cancel icon */
.cancel-icon {
  font-size: 1.4rem; /* Icon size */
  cursor: pointer;
  color: #333; /* Icon color */
  padding-left: 1rem; /* Adjust spacing from input */
}

/* Styles for the cancel icon on hover */
.cancel-icon:hover {
  color: #B22222; /* Change color on hover */
}