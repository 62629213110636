.body {
  background-color: #DCDCDC;
}
.container-policies {
    width: 70%; /* You can adjust the width as necessary */
    margin: 0 auto; /* This will center your container */
    padding: 20px;
    font-family: 'Times New Roman', Times, serif;
    line-height: 1.6;
    background-color: white;
  }
  
  .policies-top,
  .policies-middle,
  .policies-list,
  .policies-bottom {
    margin-bottom: 30px;
  }
  
  .header-policies {
    font-family: Georgia, 'Times New Roman', serif;
    text-align: center;
    color: #333; /* A dark grey color for the headings */
    margin-bottom: 20px;
  }
  
  textarea {
    width: 100%; /* Make the textarea span the full width */
    min-height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    box-sizing: border-box; /* To include padding in the width */
    border: 1px solid #ccc;
    border-radius: 4px; /* Rounded corners for the textarea */
  }
  
  button {
    background-color: grey; /* A nice blue shade */
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s; /* Smooth transition for hover effect */
    cursor: pointer;
    border-radius: 4px; /* Rounded corners for buttons */
  }
  
  button:hover {
    background-color: #B22222; /* A darker blue on hover */
    color: white;
  }
  
  ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0;
  }
  
  li {
    margin-bottom: -10px;
    padding: 10px;
    border-radius: 4px;
  }
  
