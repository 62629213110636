
.auth-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .auth-form {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px #00000033;
    max-width: 500px;
    width: 100%;
  }
  
  .auth-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .auth-form button {
    padding: 10px 20px;
    background-color: #B22222;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .auth-form button:hover {
    background-color: #B22222;
  }
  
  .error-message {
    color: red;
    margin-top: 20px;
  }

  .forgot-password-link {
    display: inline-block;
    margin-left: 20px; /* Adjust the value as needed */
    color: #007bff;
    cursor: pointer;
    text-decoration: underline;
  }
  
  
  .forgot-password-link:hover {
    text-decoration: none;
  }

  .reset-email-box {
    padding-top: 20px;
  }
  
  /* Container when a user is logged in */
.logged-in-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #808080;
}

/* Box showing user is logged in */
.logged-in-box {
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px #00000033;
  max-width: 500px;
  width: 100%;
  text-align: center; /* Centering the text and the button */
}

/* Logout button */
.logout-button {
  padding: 10px 20px;
  background-color: #dc3545; /* Different color for logout button */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}