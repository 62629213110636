.search-results {
  width: 90%;  /* Adjust this to your desired width, e.g., 80%, 70%, etc. */
  max-width: 100%;  /* Optional: set a maximum width */
  margin-top: 10px;  /* Top-bottom margin and auto left-right margin for centering */
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid #000;
  border-radius: 2px;
  background-color: #ffffff;
  text-align: center;
}

.articles-row-search {
  width: 95%;
  margin: 0 auto;
  display: flex;
}

.article-card-search {
  flex: 1;  
  margin: 0 20px 20px 20px;  /* Top: 0, Right: 15px, Bottom: 20px, Left: 15px */
  /* width: calc(33.33% - 30px);  Ensuring that after adding the margin, the width still sums up to be about one-third of the container width */
  overflow: hidden;
  border: 3px solid black;
  border-radius: 2px;
  /* box-shadow: 0px 0px 10px 0px #D3D3D3; */
  padding: 10px;

}
.article-image-search {
  
    display: block;       /* Images are inline by default. Change to block to apply margin */
    margin: 0 auto;       /* This will apply an automatic margin to left and right */
    max-width: 100%;      /* This ensures the image is responsive and doesn't overflow its container */
    height: auto; 
    max-height: 250px;
 
   
 }

 .article-content {
  width: 90%;

  text-align: center; 
  
}

.search-image-container {
  display: flex;
  justify-content: center;  /* Aligns children (image) on the horizontal axis in the center */
  align-items: center;  
  width: 100%;
}

.author-email-link {
  font-size: 0.9em;
  color: black; /* Set your desired color */
  text-decoration: none; 
  }

  .author-email {
    font-size: 0.9em;
    }
    
    .author-email-link:hover {
    text-decoration: underline; /* Add underline when hovered */
    }
    
    .description {
    text-align: left;
    }
  


