/* Individual StaffPerson styling */
.staff-person {
    flex: 1 1 30%; /* Here, 30% allows three boxes in a row */
    box-sizing: border-box;
    min-height: 300px;
    min-width: 300px;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 1.66%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-align: center;
  }
  
  .staff-image {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ddd;
  }
  
  
  .delete-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #ff6262;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .delete-button:hover {
    background-color: #ff3f3f;
  }
  
  .staff-name, .staff-title {
    margin: 10px 0;
    text-align: center;
  }
  
  .staff-bio {
    font-size: 18px;
    line-height: 1.5;
    margin: 10px 0;
    color: black;
    text-align: left;
  }
  
  /* Individual StaffPerson styling */
.staff-person {
    flex: 1 1 30%; /* Here, 30% allows three boxes in a row */
    box-sizing: border-box;
    max-width: 30%;
    border: 1px solid #b22222;
    padding: 10px;
    margin: 1.66%;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .staff-person h2 {
    color: #b22222;
  }