/* styles.css */

.container-advertising {
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
    font-family: 'Times New Roman', Times, serif;
    background-color: white;
  }

  .container-advertising h1 {
    text-size-adjust: 10px;
    padding-bottom: 20px;
  }
  
  .container-advertising a {
    color: #007bff;
    text-decoration: none;
    margin-bottom: 15px;
    display: inline-block;
  }
  
  .container-advertising a:hover {
    text-decoration: underline;
  }
  
  .advertising {
    border-radius: 5px;
    
  }
  
  textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.6;
  }
  
  button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #218838;
  }
  
  p {
    margin-bottom: 15px;
  }
