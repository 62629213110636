.footer {
  width: auto;
  height: 200px;
  background-color: grey;
  display: flex;
  justify-content: space-between; /* This separates the child elements */
  align-items: center; /* This vertically centers the child elements */
  padding: 20px; /* Add padding for some breathing room */
  overflow: hidden;
}

.footer-left,
.footer-middle,
.footer-right {
  flex: 1; /* Each child will take up equal space */
  display: flex; /* Enables flexbox for each section */
  align-items: center; /* Vertically center the content */
}

.footer-left {
  justify-content: flex-start; /* Aligns items to the left */
}

.footer-middle {
  justify-content: center; /* Centers items horizontally */
  flex-direction: column; /* Stacks items vertically */
  text-align: center; /* Aligns the text to the center for stacked items */
  margin-top: 20px;
}

.footer-right {
  justify-content: flex-end; /* Aligns items to the right */
}

.logo {
  width: auto; /* keep the width proportional to the height */
  height: 160px;
  margin: auto;
  
}

.footer-middle {
  display: flex; /* Use flexbox to align items */
  flex-direction: column; /* Stack flex items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
}

.social-links {
  display: flex; /* Align social icons horizontally */
  justify-content: center; /* Center icons horizontally */
  gap: 20px; /* Space between icons */
}

.social-link {
  display: flex; /* Make each link a flex container to center the icon inside it */
  align-items: center; /* Center the icon vertically */
  justify-content: center; /* Center the icon horizontally */
}

.pol-adv-copy {
  display: flex; /* Use flexbox to align items */
  flex-direction: column; /* Stack flex items vertically */
  align-items: center; /* Center items horizontally */
  margin-top: 10px; /* Add space between icons and text links */
  line-height: 1.5rem;
  color: black;
}

.social-links a .social-icon {
  font-size: 60px; /* default font size for icons */
  color: white; /* icon color */
}

/* Responsive styles for screens less than 1150px wide */
@media (max-width: 1150px) {
  .footer {
    display: flex; /* Use flexbox to align items */
    flex-direction: column; /* Stack flex items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    width: auto;

  }

  .footer-left,
  .footer-middle,
  .footer-right {
    margin-bottom: 4px; /* Add space between the stacked items */
  }

  .social-links a {
    font-size: 10px; /* Adjust icon size for smaller screens */
  }

  .pol-adv-copy p,
  .policies-link,
  .advertising-link {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
  
  .social-links a .social-icon {
    font-size: 30px; /* default font size for icons */
    color: white; /* icon color */
  }

  .navbar-logo {
    width: 100px;
    height: auto;
  }


  .logo {
    display: none;
  }
}
